import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Label from './Label';
import ButtonRemoveImage from './ButtonRemoveImage';
import ButtonAdd from './ButtonAdd';
import axiosInstance from '../Utils/axiosInstance';
import { PRODUCT_ENDPOINT, PRODUCT_LIST_ENDPOINT } from '../Utils/Endpoints';
import * as Yup from 'yup';
import RichTextEditor from './RichTextEditor';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import { getItem, getObject, saveObject } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';
import SkeletonLoader from './SkeletonLoader';
import Logger from '../Utils/Logger';
import RangeNotFound from './RangeNotFound';
import DeleteModal from './DeleteModal';

// Validation schema
const ProductRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  basePrice: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    })
    .nullable()
    .required('Required')
    .moreThan(0, 'Price must be greater than 0'),
});

const AddClassProduct = ({ handleNextStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [basePrice, setBasePrice] = useState(0.0);
  const [sellingPrice, setSellingPrice] = useState(0.0);
  const [stock, setStock] = useState(0);
  const [minOrderQty, setMinOrderQty] = useState(1);
  const [isActive, setIsActive] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [productID, setProductID] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const rangeId = getItem('rangeId');
  const location = useLocation();
  const selectedCourse = location.state && location.state.course;

  const [createdClassData] = useState(
    getObject('classData') ? getObject('classData') : selectedCourse
  );

  // productList
  const [productList, setProductList] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PRODUCT_LIST_ENDPOINT + `${rangeId}?course_id=${createdClassData?.id}`
      );
      if (response.data.code === 200) {
        setProductList(response.data.data.data);
        saveObject('classProductData', response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedProduct?.seller_products &&
      selectedProduct?.seller_products[0]?.id
    ) {
      setProductID(selectedProduct?.seller_products[0]?.id);
      setName(selectedProduct?.seller_products[0]?.name);
      setIsActive(selectedProduct?.seller_products[0]?.is_active);
      setMinOrderQty(
        selectedProduct?.seller_products[0]?.min_order_qty !== null
          ? selectedProduct?.seller_products[0]?.min_order_qty
          : 1
      );
      setStock(
        selectedProduct?.seller_products[0]?.stock !== null
          ? selectedProduct?.seller_products[0]?.stock
          : 0
      );
      setSellingPrice(selectedProduct?.min_selling_price);
      setBasePrice(selectedProduct?.seller_products[0]?.base_price);
      setDescription(
        selectedProduct?.seller_products[0]?.description !== null
          ? selectedProduct?.seller_products[0]?.description
          : ''
      );
    }
  }, [selectedProduct]);

  const validate = () => {
    try {
      ProductRangeSchema.validateSync(
        {
          name,
          description,
          basePrice,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      if (!productID) {
        const response = await axiosInstance.post(PRODUCT_ENDPOINT + rangeId, {
          name: name,
          description: description,
          type: 'TICKET',
          sku: null,
          base_price: basePrice ? parseFloat(basePrice) : basePrice,
          selling_price: sellingPrice ? parseFloat(sellingPrice) : sellingPrice,
          min_order_qty: parseFloat(minOrderQty),
          stock: parseFloat(stock),
          is_active: isActive ? 1 : 0,
          course_id: createdClassData && parseInt(createdClassData?.id),
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        setProductID(response.data.data.id);
        const modalClose = document.getElementsByClassName(
          'close-product-modal'
        );
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        await fetchProductList();
        Toast.success('Product created.', {
          onClose: () => {
            handleReset();
          },
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      const response = await axiosInstance.patch(
        PRODUCT_ENDPOINT +
          selectedProduct?.seller_products[0]?.id +
          `/${rangeId}`,
        {
          name: name,
          description: description,
          type: 'TICKET',
          sku: null,
          model: null,
          brand_id: null,
          length: null,
          width: null,
          height: null,
          weight: null,
          dimension_unit: null,
          weight_unit: null,
          is_featured: 0,
          base_price: basePrice ? parseFloat(basePrice) : basePrice,
          selling_price: sellingPrice ? parseFloat(sellingPrice) : sellingPrice,
          min_order_qty: parseFloat(minOrderQty),
          stock: parseFloat(stock),
          is_active: isActive ? 1 : 0,
          course_id: createdClassData && parseInt(createdClassData?.id),
        }
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      setProductID(response.data.data.id);

      const modalClose = document.getElementsByClassName('close-product-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      await fetchProductList();
      Toast.success(
        response?.data?.message
          ? response?.data?.message
          : 'Product successfully updated.',
        {
          onClose: () => {
            handleReset();
          },
          autoClose: 2000,
        }
      );
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setErrors({});
    setGeneralError('');
    setProductID(null);
    setIsActive(false);
    setMinOrderQty(1);
    setStock(0);
    setSellingPrice(0.0);
    setBasePrice(0.0);
    setDescription('');
    setName('');
    setSelectedProduct({});
  };

  return (
    <>
      <div className="employees employ-set pt-4 pt-md-1">
        <div className="back-listing-tab">
          <h3 className="card-top-2">Product</h3>
        </div>
        <div className="all-date-selected mobile-b-set new-back-btn-add">
          <Link
            to="#"
            className="button-width"
            data-bs-toggle="modal"
            data-bs-target="#productClassModal"
          >
            <ButtonAdd
              Heading="ADD"
              images="../assets/icon/plaus_icon.svg"
              active="orange-outline"
              bgChange="white-bg w-pd-add"
            />
          </Link>
          <Link onClick={handleNextStep} className="button-width">
            <ButtonRemoveImage
              Heading="Next"
              active="gary-light-outline"
              bgChange="gary-bg w-pd-add"
            />
          </Link>
        </div>
      </div>
      <form className="triangle-top">
        <div className="body-text text-start add-pro">
          <div className="hours">
            {isLoading && <SkeletonLoader />}
            {productList?.length === 0 && !isLoading && (
              <RangeNotFound title="Products" />
            )}
            {productList?.length > 0 && !isLoading && (
              <>
                <div className="create-box">
                  {productList?.map((item, index) => (
                    <div className="create-box__leans" key={index}>
                      <div className="card-icon">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#productClassModal"
                          onClick={() => setSelectedProduct(item)}
                        >
                          <img src="../assets/icon/edit.svg" alt="" />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                          onClick={() =>
                            setSelectedData({
                              id: item?.seller_products[0]?.id,
                              type: 'product_list',
                            })
                          }
                        >
                          <img src="../assets/icon/delete.svg" alt="" />
                        </Link>
                      </div>
                      <h4>{item?.seller_products[0]?.name}</h4>
                      <p>Type: {item.type}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </form>

      <div
        className="modal fade"
        id="productClassModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                {selectedProduct?.id ? 'Update' : 'Add '} Product
              </h5>
            </div>

            <form onSubmit={selectedProduct?.id ? handleUpdate : handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4">
                    <Label labelHeading="Product Name" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        placeholder=""
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      {errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label labelHeading="Product Regular Price" />
                    <div className="tickets">
                      <input
                        type="number"
                        className="form-control input-theme"
                        placeholder="$"
                        onChange={(e) => setBasePrice(e.target.value)}
                        value={basePrice}
                      />
                      {errors.basePrice && (
                        <div className="error">{errors.basePrice}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label labelHeading="Product Sale Price" />
                    <div className="tickets">
                      <input
                        type="number"
                        className="form-control input-theme"
                        placeholder="$"
                        onChange={(e) => setSellingPrice(e.target.value)}
                        value={sellingPrice}
                      />
                      {errors.sellingPrice && (
                        <div className="error">{errors.sellingPrice}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label labelHeading="Stock" />
                    <div className="tickets">
                      <input
                        type="number"
                        className="form-control input-theme"
                        placeholder=""
                        onChange={(e) => setStock(e.target.value)}
                        value={stock}
                      />
                      {errors.stock && (
                        <div className="error">{errors.stock}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label labelHeading="Min Order Qty" />
                    <div className="tickets">
                      <input
                        type="number"
                        className="form-control input-theme"
                        onChange={(e) => setMinOrderQty(e.target.value)}
                        value={minOrderQty}
                      />
                      {errors.minOrderQty && (
                        <div className="error">{errors.minOrderQty}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-check mb-5 mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Label labelHeading="Product Description" />
                    <div className="tickets-2">
                      <RichTextEditor
                        value={description}
                        setEditorData={setDescription}
                        index="description"
                      />
                      {errors.description && (
                        <div className="error">{errors.description}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center mt-3">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    type="submit"
                    bgChange={`white-bg w-pd-add`}
                    active="orange-outline"
                    isLoading={isLoading}
                  >
                    {selectedProduct?.id ? 'Update' : 'Save'}
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-product-modal"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={fetchProductList}
      />
    </>
  );
};

AddClassProduct.propTypes = {
  handleNextStep: PropTypes.func,
};
export default AddClassProduct;
