import React, { useEffect, useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import { Link } from 'react-router-dom';
// import MangeBay from '../Components/MangeBay';
import ModalLaneInput from '../Components/ModalLaneInput';
import AssociateRangesModal from '../Components/AssociateRangesModal';
import AssociateRangesCard from '../Components/AssociateRangesCard';
import TrainersModal from '../Components/TrainersModal';
import TrainersCard from '../Components/TrainersCard';
import OperationHoursModal from '../Components/OperationHoursModal';
import SocialLinksModal from '../Components/SocialLinksModal';
import DeleteModal from '../Components/DeleteModal';
import axiosInstance from '../Utils/axiosInstance';

import {
  LIST_BAY_ENDPOINT,
  RANGE_ASSOCIATED_RANGES_ENDPOINT,
  RANGE_OPERATION_HOUR_ENDPOINT,
  RANGE_PROFICIENCY_LIST_ENDPOINT,
  RANGE_SOCIAL_LINK_ENDPOINT,
  RANGE_TRAINERS_ENDPOINT,
} from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import SkeletonLoader from '../Components/SkeletonLoader';
import { getItem, saveItem } from '../Utils/LocalStorage';
import RangeNotFound from '../Components/RangeNotFound';
import FPE from '../Components/FPE';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import OperationHours from '../Components/OperationHours';
import ModalSamartWavier from '../Components/ModalSamartWavier';

const Manage = () => {
  const [socialLinkList, setSocialLinkList] = useState([]);
  const [smartWaiverList] = useState([]);
  const [operationHourList, setOperationHourList] = useState([]);
  const [associateRangeList, setAssociateRangeList] = useState([]);
  const [trainerRangeList, setTrainerRangeList] = useState([]);
  const [bayList, setBayList] = useState([]);
  const [firearm, setFirearm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSocialLink, setSelectedSocialLink] = useState({});
  const [selectedBay, setSelectedBay] = useState({});
  const [selectedBayId, setSelectedBayId] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const rangeId = getItem('rangeId');

  const [isActive, setIsActive] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
    tab7: false,
    tab8: false,
  });

  const handleTabClick = (tab) => {
    setIsActive((prevIsActive) => {
      return { ...prevIsActive, [tab]: true };
    });

    Object.keys(isActive).forEach((key) => {
      if (key !== tab) {
        setIsActive((prevIsActive) => {
          return { ...prevIsActive, [key]: false };
        });
      }
    });
  };

  const handleEditSocialClick = (item) => {
    setSelectedSocialLink(item);
  };

  const handleEditBay = (item) => {
    setSelectedBay(item);
  };
  const handleBayId = (id) => {
    setSelectedBayId(id);
  };

  useEffect(() => {
    if (isActive.tab1) {
      fetchManageBay();
      Logger.log('isActive.tab1');
    } else if (isActive.tab2) {
      fetchAssociateRange();
    } else if (isActive.tab3) {
      fetchTrainerRangeList();
    } else if (isActive.tab4) {
      fetchOperationHour();
    } else if (isActive.tab5) {
      fetchSocialLink();
    } else if (isActive.tab6) {
      fetchFirearm();
    } else if (isActive.tab7) {
      fetchSmartWaiver();
    }
  }, [isActive]);

  const fetchSocialLink = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_SOCIAL_LINK_ENDPOINT + rangeId + '/list'
      );
      if (response.data.code === 200) {
        setSocialLinkList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSmartWaiver = async () => {
    try {
      setIsLoading(true);
      // const response = await axiosInstance.get(
      //   RANGE_SOCIAL_LINK_ENDPOINT + rangeId + '/list'
      // );
      // if (response.data.code === 200) {
      //   setSmartWaiverList(response.data.data);
      // }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOperationHour = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_OPERATION_HOUR_ENDPOINT +
          `/list?owner_class=${RANGE_CLASS}&owner_id=${rangeId}`
      );
      if (response.data.code === 200) {
        setOperationHourList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssociateRange = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_ASSOCIATED_RANGES_ENDPOINT + rangeId + '/list'
      );
      if (response.data.code === 200) {
        setAssociateRangeList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrainerRangeList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_TRAINERS_ENDPOINT + rangeId + '/list'
      );
      if (response.data.code === 200) {
        setTrainerRangeList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFirearm = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_PROFICIENCY_LIST_ENDPOINT +
          `?owner_class=${RANGE_CLASS}&owner_id=` +
          rangeId
      );
      if (response.data.code === 200) {
        setFirearm(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchManageBay = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(LIST_BAY_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setBayList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (id, type, imageId) => {
    if (type === 'firearm' && imageId) {
      setSelectedData({
        id,
        type,
        imageId,
      });
    } else {
      setSelectedData({
        id,
        type,
      });
    }
  };

  const handleUpdateList = (item, type) => {
    switch (type) {
      case 'ADD':
        setSocialLinkList([...socialLinkList, item]);
        break;
      case 'UPDATE':
        setSocialLinkList(
          socialLinkList.map((link) => (link.id === item.id ? item : link))
        );
        break;
      case 'DELETE':
        setSocialLinkList(socialLinkList.filter((link) => link.id !== item));
        break;
      default:
        Logger.error(`Invalid type: ${type}`);
    }
  };

  const handleUpdateHourList = (item, type) => {
    switch (type) {
      case 'ADD':
        fetchOperationHour();
        break;
      case 'DELETE':
        setOperationHourList(
          operationHourList.filter((link) => link.id !== item)
        );
        break;
      default:
        Logger.error(`Invalid type: ${type}`);
    }
  };

  const handleUpdateAssociateRangeList = (item, type) => {
    switch (type) {
      case 'ADD':
        setAssociateRangeList([...associateRangeList, item]);
        break;
      case 'DELETE':
        setAssociateRangeList(
          associateRangeList.filter((link) => link.associated_range_id !== item)
        );
        break;
      default:
        Logger.error(`Invalid type: ${type}`);
    }
  };

  const handleUpdateTrainerRangeList = (item, type) => {
    switch (type) {
      case 'ADD':
        setTrainerRangeList([...trainerRangeList, item]);
        break;
      case 'DELETE':
        setTrainerRangeList(
          trainerRangeList.filter((link) => link.user.id !== item)
        );
        break;
      default:
        Logger.error(`Invalid type: ${type}`);
    }
  };

  const handleUpdateFirearmList = (item, type) => {
    switch (type) {
      case 'ADD':
        setFirearm([...firearm, item]);
        break;
      case 'DELETE':
        setFirearm(firearm.filter((link) => link.id !== item));
        break;
      default:
        Logger.error(`Invalid type: ${type}`);
    }
  };

  const handleViewLanes = (bayData) => {
    saveItem('bayData', bayData);
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <ul
                  className="nav nav nav-tabs mt-3 nav-event-pro tab-nav-list"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <div
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-one"
                    >
                      <button
                        type="button"
                        className={
                          'nav-link ' + (isActive.tab1 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab1')}
                      >
                        Manage Bay&apos;s
                      </button>
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div
                      className=""
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                    >
                      <button
                        type="button"
                        onClick={() => handleTabClick('tab2')}
                        className={
                          ' nav-link ' + (isActive.tab2 ? 'active-tab' : '')
                        }
                      >
                        Manage Ranges
                      </button>
                    </div>
                  </li>
                  <li className="nav-item " role="presentation">
                    <div
                      className=""
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab3 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab3')}
                      >
                        Instructors
                      </button>
                    </div>
                  </li>
                  <li className="nav-item " role="presentation">
                    <div
                      className=""
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab4 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab4')}
                      >
                        Operation Hours
                      </button>
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div
                      className=""
                      id="pills-two-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-two"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab5 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab5')}
                      >
                        Social Links
                      </button>
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div
                      className=""
                      id="pills-fpe-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-fpe"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab6 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab6')}
                      >
                        FPE
                      </button>
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div
                      className=""
                      id="pills-smart-waiver-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-smart-waiver"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab7 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab7')}
                      >
                        Smart Waiver
                      </button>
                    </div>
                  </li>
                  <li className="nav-item" role="presentation">
                    <div
                      className=""
                      id="pills-range-policy-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-range-policy"
                    >
                      <button
                        type="button"
                        className={
                          ' nav-link ' + (isActive.tab8 ? 'active-tab' : '')
                        }
                        onClick={() => handleTabClick('tab8')}
                      >
                        Range Policy
                      </button>
                    </div>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade " id="pills-home">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Manage Ranges</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#associateRanges"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <from className="triangle-top">
                      <div className="body-text text-start">
                        {associateRangeList?.length > 0 && (
                          <div className=" card-gun-id">
                            {associateRangeList?.map((item, index) => (
                              <AssociateRangesCard
                                key={index}
                                hexBg="bg-black"
                                hexOuterLine="bg-width-outline"
                                UserImages="../assets/images/temp-range.png"
                                RangeName={item?.associated_range?.name}
                                deleteImages="./assets/icon/delete.svg"
                                Address="	"
                                handleDelete={handleDelete}
                                associateRangeData={item}
                              />
                            ))}
                          </div>
                        )}
                        {associateRangeList?.length === 0 && !isLoading && (
                          <RangeNotFound title="Range" />
                        )}
                        {isLoading && <SkeletonLoader />}
                      </div>
                    </from>
                  </div>
                  <div className="tab-pane fade" id="pills-profile">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Instructors</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#trainersModal"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start">
                        {trainerRangeList?.length > 0 && (
                          <div className="card-gun-id">
                            {trainerRangeList?.map((item, index) => (
                              <TrainersCard
                                key={index}
                                hexBg="bg-black"
                                hexOuterLine="bg-width-outline"
                                UserImages={
                                  item?.user?.profile_picture
                                    ? item?.user?.profile_picture?.fullUrl
                                    : '../assets/images/profile.png'
                                }
                                Name={item.user.name}
                                EmailUser={item.user.email}
                                UserText=""
                                // UserText="Atlanta, GA | Stoddard´s Range & Guns Profile 100% Complete"
                                UserExperience={
                                  (item?.user?.role
                                    ? item?.user?.role[
                                        item?.user?.role?.length - 1
                                      ]?.roleName
                                    : 'INSTRUCTOR') +
                                  ' | ' +
                                  item?.user?.skill_level
                                }
                                trainerData={item}
                                handleDelete={handleDelete}
                              />
                            ))}
                          </div>
                        )}
                        {trainerRangeList?.length === 0 && !isLoading && (
                          <RangeNotFound title="Instructors" />
                        )}
                        {isLoading && <SkeletonLoader />}
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="pills-contact">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Operation Hours</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#OperationHoursModal"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start">
                        <div className="hours">
                          {operationHourList?.length > 0 && (
                            <div className="table-responsive w-100">
                              <table className="table table-time-gun-create mobile-responsive time-select">
                                <thead>
                                  <tr>
                                    <th>Day</th>
                                    <th>Open Time</th>
                                    <th>Close Time</th>
                                    <th>Open</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody className="table-time-gun-create__old-even">
                                  {operationHourList?.map((item, index) => (
                                    <tr key={index}>
                                      <td data-label="Day">{item.dayName}</td>
                                      <td data-label="Start">
                                        <p>{item.start}</p>
                                      </td>
                                      <td data-label="End">
                                        <p>{item.end}</p>
                                      </td>
                                      <td data-label="End">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="active"
                                          checked={item.is_open}
                                        />
                                      </td>
                                      <td className="td-left">
                                        <div className="edit-user-id edit-link">
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            className="dan-col border-red"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                'operation_hour'
                                              )
                                            }
                                          >
                                            <img
                                              src="../assets/icon/delete.svg"
                                              alt=""
                                            />
                                            {/* <span className="color-red">
                                                Delete
                                              </span> */}
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {operationHourList?.length === 0 && !isLoading && (
                            <RangeNotFound title="Operation Hours" />
                          )}
                          {isLoading && (
                            <SkeletonLoader type="table" rows={6} columns={4} />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="pills-two">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Social Links</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          className="button-width"
                          data-bs-toggle="modal"
                          data-bs-target="#socialLink"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start">
                        <div className="hours">
                          {socialLinkList?.length > 0 && (
                            <div className="table-responsive w-100">
                              <table className="table table-time-gun-create mobile-responsive time-select">
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>URL</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody className="table-time-gun-create__old-even">
                                  {socialLinkList?.map((item, index) => (
                                    <tr key={index}>
                                      <td data-label="Type">
                                        <p>{item.type}</p>
                                      </td>
                                      <td data-label="URL">
                                        <p>{item.url}</p>
                                      </td>
                                      <td className="td-left">
                                        <div className="edit-user-id edit-link">
                                          <Link
                                            className="or-col border-theme"
                                            data-bs-toggle="modal"
                                            data-bs-target="#socialLink"
                                            onClick={() =>
                                              handleEditSocialClick(item)
                                            }
                                          >
                                            <img
                                              src="../assets/icon/edit.svg"
                                              alt=""
                                            />
                                            {/* <span className="color-theme">
                                                Edit
                                              </span> */}
                                          </Link>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            className="dan-col border-red"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                'social_link'
                                              )
                                            }
                                          >
                                            <img
                                              src="../assets/icon/delete.svg"
                                              alt=""
                                            />
                                            {/* <span className="color-red">
                                                Delete
                                              </span> */}
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {socialLinkList?.length === 0 && !isLoading && (
                            <RangeNotFound title="Social Links" />
                          )}
                          {isLoading && (
                            <SkeletonLoader type="table" rows={6} columns={4} />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="pills-fpe">
                    <FPE
                      handleDelete={handleDelete}
                      firearm={firearm}
                      fetchFirearm={fetchFirearm}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="tab-pane fade show active" id="pills-one">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Manage Bay&apos;s</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#modalLane"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start">
                        {bayList?.length === 0 && !isLoading && (
                          <RangeNotFound title="Manage Bay's" />
                        )}
                        {isLoading && <SkeletonLoader />}
                        <div className="create-box">
                          {bayList?.length > 0 &&
                            bayList?.map((item, index) => (
                              <Link
                                to="/ranges/manage-lanes"
                                className="create-box__leans"
                                key={index}
                                onClick={() => handleViewLanes(item)}
                              >
                                <div className="card-icon">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#OperationHours"
                                    onClick={() => handleBayId(item.id)}
                                  >
                                    <img
                                      src="../assets/icon/clock-icon.svg"
                                      alt=""
                                    />
                                  </Link>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalLane"
                                    onClick={() => handleEditBay(item)}
                                  >
                                    <img src="../assets/icon/edit.svg" alt="" />
                                  </Link>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() =>
                                      handleDelete(item.id, 'manage_bay')
                                    }
                                  >
                                    <img
                                      src="../assets/icon/delete.svg"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <h4>{item.name}</h4>
                                <p>Lanes: {item.lanes_count}</p>
                              </Link>

                              // <MangeBay
                              //   lanesImages="../assets/icon/edit.svg"
                              //   lanesImagesDelete="../assets/icon/delete.svg"
                              //   bayHeading={item.name}
                              //   lanes="Lanes: 8"
                              //   key={index}
                              // />
                            ))}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="pills-smart-waiver">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2">Smart Waiver</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width b-p"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#modalSmartWaiver"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start">
                        <div className="hours">
                          {smartWaiverList?.length > 0 && (
                            <div className="table-responsive w-100">
                              <table className="table table-time-gun-create mobile-responsive time-select">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody className="table-time-gun-create__old-even">
                                  {smartWaiverList?.map((item, index) => (
                                    <tr key={index}>
                                      <td data-label="Type">
                                        <p>{item.type}</p>
                                      </td>
                                      <td data-label="URL"></td>
                                      <td className="td-left">
                                        <div className="edit-user-id edit-link">
                                          <Link className="or-col border-theme">
                                            <img
                                              src="../assets/icon/view-eye.svg"
                                              alt=""
                                            />
                                          </Link>

                                          <Link
                                            className="or-col border-theme"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalSmartWaiver"
                                          >
                                            <img
                                              src="../assets/icon/edit.svg"
                                              alt=""
                                            />
                                          </Link>
                                          <Link className="or-col border-theme">
                                            <img
                                              src="../assets/icon/duplicate_.svg"
                                              alt=""
                                            />
                                          </Link>
                                          <Link className="or-col border-theme">
                                            <img
                                              src="../assets/icon/primary_.svg"
                                              alt=""
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            className="dan-col border-red"
                                          >
                                            <img
                                              src="../assets/icon/delete.svg"
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {smartWaiverList?.length === 0 && !isLoading && (
                            <RangeNotFound title="Smart Waiver" />
                          )}
                          {smartWaiverList && (
                            <SkeletonLoader type="table" rows={6} columns={4} />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="pills-range-policy">
                    <div className="employees employ-set pt-4 pt-md-1">
                      <div className="back-listing-tab">
                        <Link
                          to="/ranges/range-locations"
                          className="button-width"
                        >
                          <ButtonAdd
                            Heading=""
                            images="../assets/icon/back-icon-page.svg"
                            active="orange-outline"
                            bgChange="white-bg "
                          />
                        </Link>
                        <h3 className="card-top-2"> Range Policy</h3>
                      </div>
                      <div className="all-date-selected mt-3 new-back-btn-add"></div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text text-start"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={handleUpdateFunction()}
      />
      <SocialLinksModal
        selectedSocialLink={selectedSocialLink}
        handleUpdateList={handleUpdateList}
        setSelectedSocialLink={setSelectedSocialLink}
      />
      <OperationHoursModal handleUpdateList={handleUpdateHourList} />
      <TrainersModal fetchTrainerRangeList={fetchTrainerRangeList} />
      <AssociateRangesModal fetchAssociateRange={fetchAssociateRange} />
      <ModalLaneInput
        headingBay={(selectedBay?.id ? 'Update' : 'Create') + ' Range Bay'}
        selectedBay={selectedBay}
        setSelectedBay={setSelectedBay}
        handleUpdateList={fetchManageBay}
      />

      <OperationHours
        selectedBay={selectedBayId}
        setSelectedBay={setSelectedBayId}
      />

      <ModalSamartWavier />
    </>
  );

  function handleUpdateFunction() {
    return selectedData?.type === 'operation_hour'
      ? handleUpdateHourList
      : selectedData?.type === 'social_link'
        ? handleUpdateList
        : selectedData?.type === 'firearm'
          ? handleUpdateFirearmList
          : selectedData?.type === 'trainer'
            ? handleUpdateTrainerRangeList
            : selectedData?.type === 'manage_bay'
              ? fetchManageBay
              : handleUpdateAssociateRangeList;
  }
};

export default Manage;
