import React from 'react';

const RangeActivities = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2 range-a">Range Activities</h3>
                  <div className="all-date-selected">
                    <div className="a-and-n">
                      <div className="empty">
                        <p>Empty</p>
                      </div>
                      <div className="reserved">
                        <p>Reserved</p>
                      </div>
                      <div className="choice">
                        <p>Your Choice</p>
                      </div>
                      <div className="booked">
                        <p>Closed</p>
                      </div>
                    </div>
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                    <div className="date-input tickets">
                      <input
                        type="time"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="active-not-active-range">
                          <p className="range-heading">Bay 1 - Midtown</p>
                          <ul>
                            <li className="bg-org">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              1
                            </li>
                            <li className="bg-red">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              2
                            </li>
                            <li className="bg-black-lane">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              3
                            </li>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              4
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="active-not-active-range">
                          <p className="range-heading">Bay 1 - Midtown</p>
                          <ul>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              1
                            </li>
                            <li className="bg-red">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              2
                            </li>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              3
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="active-not-active-range">
                          <p className="range-heading">Bay 1 - Midtown</p>
                          <ul>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              1
                            </li>
                            <li className="bg-red">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              2
                            </li>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              3
                            </li>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              4
                            </li>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              5
                            </li>
                            <li className="bg-red">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              6
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="active-not-active-range">
                          <p className="range-heading">Bay 3 - Midtownn</p>
                          <ul>
                            <li className="bg-gray">
                              <img
                                src="../assets/icon/range_target.svg"
                                alt=""
                              />
                              1
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RangeActivities;
