import React, { useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import { Link, useLocation } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ReasonModal from '../Components/ReasonModal';
import CustomItem from '../Components/CustomItem';

const ProductOrderDetails = () => {
  const [showComment, setShowComment] = useState(false);
  const location = useLocation();
  const selectedOrder = location?.state && location?.state?.order;

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/store/orders" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">ORDERS DETAILS</h3>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link to="/store/orders" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>

                    <img
                      src="../assets/icon/download_icon.svg"
                      alt=""
                      className="p-d-icon ms-auto"
                    />
                    <img
                      src="../assets/icon/printer_new.svg"
                      alt=""
                      className="p-d-icon"
                    />
                    <ButtonRemoveImage
                      Heading="Refund"
                      active="orange-outline "
                      bgChange="white-bg"
                    />
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="order-det">
                        <div className="main-order">
                          <div className="ord-det">
                            <h4>
                              #{selectedOrder?.order_number}{' '}
                              {selectedOrder?.order_status?.identifier ===
                              'Payment Confirmed' ? (
                                <span>Paid</span>
                              ) : (
                                <span className="span-2">Unfulfilled</span>
                              )}
                            </h4>
                            <p>
                              {selectedOrder?.created_at} With{' '}
                              {selectedOrder?.transactions[0].modeText}
                            </p>
                            {/* <p>July 24, 2023 at 3:35 am from Online Store</p> */}
                          </div>
                          <div className="re-fund-edit d-none">
                            <Link to="#">Refund</Link>
                            <Link
                              to="#"
                              // data-bs-target="#reasonModal"
                              // data-bs-toggle="modal"
                            >
                              Edit
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="listing-product">
                          <ul>
                            <li>
                              <p>
                                {selectedOrder?.order_items &&
                                  selectedOrder?.order_items[0]?.holder?.bay
                                    ?.name}
                                {' - '}
                                {selectedOrder?.order_items &&
                                  selectedOrder?.order_items[0]?.holder?.name}
                              </p>
                            </li>
                            <li className="d-none">
                              <p>
                                $
                                {selectedOrder?.order_items[0]?.holder?.bay?.base_reservation_cost?.toFixed(
                                  2
                                )}
                                x 1
                              </p>
                            </li>
                            <li>
                              <p>
                                $
                                {selectedOrder?.order_items[0]?.holder?.bay?.base_reservation_cost?.toFixed(
                                  2
                                )}
                                x 1
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="second-listing-product d-none">
                          <p>Add on</p>
                          <ul>
                            <li>
                              <span>Shotgun</span>
                              <span>$10.00 x 1</span>
                              <span>$10.00</span>
                            </li>
                            <li>
                              <span>Shotgun</span>
                              <span>$10.00 x 1</span>
                              <span>$10.00</span>
                            </li>
                            <li>
                              <span>Shotgun</span>
                              <span>$10.00 x 1</span>
                              <span>$10.00</span>
                            </li>
                          </ul>
                        </div>
                        <div className="paid">
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td style={{ textAlign: 'left' }}>
                                  <strong>Subtotal</strong>
                                </td>
                                <td style={{ textAlign: 'left' }}></td>
                                {/* <td style={{ textAlign: 'left' }}>4 items</td> */}
                                <td style={{ textAlign: 'right' }}>
                                  {selectedOrder?.order_totals[0]?.type ===
                                    'BASE_TOTAL' && (
                                    <strong>
                                      ${selectedOrder?.order_totals[0]?.amount}
                                    </strong>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'left' }}>
                                  {' '}
                                  <strong>Tax</strong>{' '}
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                  South Caroline State Tax 0%
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {' '}
                                  <strong>$0.00</strong>{' '}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'left' }}>
                                  {' '}
                                  <strong>Total</strong>{' '}
                                </td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>
                                  {' '}
                                  {selectedOrder?.order_totals[1]?.type ===
                                    'TOTAL' && (
                                    <strong>
                                      ${selectedOrder?.order_totals[1]?.amount}
                                    </strong>
                                  )}
                                  {/* <strong>$37.26</strong>{' '} */}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td style={{ textAlign: 'left' }}>
                                  Paid by customer
                                </td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>
                                  {' '}
                                  $
                                  {selectedOrder?.transactions[0]?.amount?.toFixed(
                                    2
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          <div className="full-items d-none">
                            <ButtonRemoveImage
                              Heading="Fulfill items"
                              bgChange="bg-orange"
                            />
                          </div>
                        </div>
                        <div className="time-line">
                          <div className="time-line_c">
                            <h4>Timeline</h4>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckChecked"
                                onChange={(e) =>
                                  setShowComment(e.target.checked)
                                }
                                checked={showComment}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                Show Comments
                              </label>
                            </div>
                          </div>
                          <div className="time-line_comments">
                            <div className="time-line_user-com">
                              <div className="email-2">
                                <div className="tickets-2">
                                  <textarea
                                    className="form-control input-theme"
                                    rows="3"
                                  ></textarea>
                                </div>
                                <p>Only you and other staff can see comments</p>
                              </div>
                            </div>
                            {showComment && (
                              <>
                                <div className="time-line_user-com-add">
                                  <div className="email">
                                    <p>
                                      $36.07 USD will be added to your Jul 26,
                                      2023 payout.
                                    </p>
                                    <p>27 minute ago</p>
                                  </div>
                                  <Link to="mailto:" className="mailto">
                                    <ButtonRemoveImage
                                      Heading="Resend mail"
                                      active="orange-outline"
                                      bgChange="white-bg"
                                    />
                                  </Link>
                                </div>
                                <div className="time-line_user-com-add">
                                  <div className="email">
                                    <p>
                                      A $37.26 USD payment was processed using a
                                      Visa ending in 2401.
                                    </p>
                                    <p>27 minute ago</p>
                                  </div>
                                </div>
                                <div className="time-line_user-com-add">
                                  <div className="email">
                                    <p>
                                      Confirmation #MZX5JMIHL was generated for
                                      this order.
                                    </p>
                                    <p>27 minute ago</p>
                                  </div>
                                </div>
                                <div className="time-line_user-com-add">
                                  <div className="email">
                                    <p>
                                      Order confirmation email was sent to
                                      Joseph Dolan II (mustangt200@hotmail.com).
                                    </p>
                                    <p>27 minute ago</p>
                                  </div>
                                </div>
                                <div className="time-line_user-com-add">
                                  <div className="email">
                                    <p>
                                      Joseph Dolan II placed this order on
                                      Online Store (checkout #34272125911297).
                                    </p>
                                    <p>27 minute ago</p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="user-det-fill-all">
                          <div className="not-card">
                            <h4>
                              Notes{' '}
                              <Link to="#">
                                <img
                                  src="../assets/icon/edit.svg"
                                  alt=""
                                  className="edit-icon-w"
                                />
                              </Link>
                            </h4>
                            <p>NO notes from customer</p>
                          </div>
                          <div className="customer">
                            <div className="joseph">
                              <h4>Customer</h4>
                              <Link to="#" className="joseph_name">
                                {selectedOrder?.user?.name} ||
                              </Link>
                              <p className="joseph_order">1 order</p>
                            </div>
                            <div className="joseph">
                              <h4>
                                Contact information{' '}
                                <Link to="#" className="joseph_ed">
                                  <img
                                    src="../assets/icon/edit.svg"
                                    alt=""
                                    className="edit-icon-w"
                                  />
                                </Link>
                              </h4>
                              <p className="joseph_m">
                                <Link
                                  to={`mailto:${selectedOrder?.user?.email}`}
                                  className="joseph_name"
                                >
                                  {selectedOrder?.user?.email}
                                </Link>
                              </p>

                              <p className="joseph_order">
                                {selectedOrder?.user?.phone_number}
                              </p>
                            </div>
                            {/* <div className="joseph">
                              <h4>
                                Contact information{' '}
                                <Link to="#" className="joseph_ed">
                                  Edit
                                </Link>
                              </h4>
                              <p className="joseph_prov">
                                No shipping address provided
                              </p>
                            </div> */}
                            <div className="joseph">
                              <h4>
                                Billing Address{' '}
                                <Link to="#">
                                  <img
                                    src="../assets/icon/edit.svg"
                                    alt=""
                                    className="joseph_edit-image"
                                  />
                                </Link>
                              </h4>
                              <p className="joseph_address-user">
                                Joseph Dolan II
                                <address>
                                  1825 Thoms Creek Ct Longs SC 29568 United
                                  States
                                </address>
                              </p>
                            </div>
                          </div>
                          <div className="summary d-none">
                            <h4>Conversion summary</h4>
                            <ul>
                              <li>
                                <p>This is their 1st order</p>
                              </li>
                              <li>
                                <p>1st session was direct to your store</p>
                              </li>
                              <li>
                                <p>1 session over 1 day</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReasonModal />
      <CustomItem />
    </>
  );
};

export default ProductOrderDetails;
