import { Link } from 'react-router-dom';
import ButtonRemoveImage from './ButtonRemoveImage';
import { getObject } from '../Utils/LocalStorage';
import React, { useState } from 'react';
import moment from 'moment';
import Toast from '../Utils/Toast';

const ReviewClass = () => {
  const [createdClassData] = useState(getObject('classData'));
  const [productData] = useState(getObject('classProductData'));

  const handleClass = () => {
    Toast.success('The class process has been successfully completed.', {
      autoClose: 2000,
    });
  };

  return (
    <div className="row">
      <div className="col-md-6">
        {createdClassData?.id && (
          <table className="table table-time-gun-create">
            <tbody className="table-time-gun-create__old-even user-all-plan-select">
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Class Name</span>
                    <span>:</span>
                  </p>
                </th>
                <td>{createdClassData?.name}</td>
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Class Description</span>
                    <span>:</span>
                  </p>
                </th>
                <td
                  dangerouslySetInnerHTML={{
                    __html: createdClassData?.description,
                  }}
                />
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Skill Level</span>
                    <span>:</span>
                  </p>
                </th>
                <td>{createdClassData?.skill_level}</td>
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Start Date & Time</span>
                    <span>:</span>
                  </p>
                </th>
                <td>
                  {moment
                    .unix(createdClassData?.start_date_time_unix)
                    .format('DD-MM-YYYY, hh:mm A')}
                </td>
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>End Date & Time</span>
                    <span>:</span>
                  </p>
                </th>
                <td>
                  {moment
                    .unix(createdClassData?.end_date_time_unix)
                    .format('DD-MM-YYYY, hh:mm A')}
                </td>
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Door open time</span>
                    <span>:</span>
                  </p>
                </th>
                <td>
                  {moment
                    .unix(createdClassData?.door_open_date_time_unix)
                    .format('hh:mm A')}
                </td>
              </tr>
              <tr>
                <th>
                  <p className="listing-event">
                    <span>Session</span>
                    <span>:</span>
                  </p>
                </th>
                <td>{createdClassData?.session}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div className="col-md-6">
        {productData && productData?.length > 0 && (
          <table className="table table-time-gun-create">
            <tbody className="table-time-gun-create__old-even user-all-plan-select">
              {productData?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th>
                      <p className="listing-event">
                        <span>Product Name</span>
                        <span>:</span>
                      </p>
                    </th>
                    <td>{item?.seller_products[0]?.name}</td>
                  </tr>
                  <tr>
                    <th>
                      <p className="listing-event">
                        <span>Product Description</span>
                        <span>:</span>
                      </p>
                    </th>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: item?.seller_products[0]?.description,
                      }}
                    />
                  </tr>
                  <tr>
                    <th>
                      <p className="listing-event">
                        <span>Price</span>
                        <span>:</span>
                      </p>
                    </th>
                    <td>{item?.seller_products[0]?.base_price}</td>
                  </tr>
                  <tr>
                    <th>
                      <p className="listing-event">
                        <span>Stock</span>
                        <span>:</span>
                      </p>
                    </th>
                    <td>{item?.seller_products[0]?.stock}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Link
        to="/upcoming/class"
        style={{ width: '150px', marginTop: '20px' }}
        onClick={handleClass}
      >
        <ButtonRemoveImage
          Heading="SAVE"
          active="orange-outline"
          bgChange="white-bg w-pd-add"
        />
      </Link>
    </div>
  );
};

export default ReviewClass;
