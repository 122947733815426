import React, { useEffect, useState } from 'react';
import HexagonSize from '../Components/HexagonSize';
import ButtonAdd from '../Components/ButtonAdd';
import { getItem } from '../Utils/LocalStorage';
import { COURSE_LIST_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import SkeletonLoader from '../Components/SkeletonLoader';
import RangeNotFound from '../Components/RangeNotFound';
import Pagination from '../Components/Pagination';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Class = () => {
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skillType, setSkillType] = useState('');
  const rangeId = getItem('rangeId');

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchCourseList();
  }, [search, currentPage, itemsPerPage]);

  const fetchCourseList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        COURSE_LIST_ENDPOINT +
          `${rangeId}?per_page=${itemsPerPage}&page=${currentPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setCourseList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Classes</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                      />
                    </div>
                    <div className="select-input tickets">
                      <select
                        className="form-select mb-0"
                        onChange={(e) => {
                          setSkillType(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={skillType}
                      >
                        <option value="">Select Skill Level</option>
                        <option value="ENTRY">ENTRY</option>
                        <option value="INTERMEDIATE">INTERMEDIATE</option>
                        <option value="EXPERT">EXPERT</option>
                      </select>
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text pt-0">
                    <div className="listing-booking">
                      <ul className="lane-booking mb-0">
                        <li>
                          <img src="../assets/icon/user.svg" alt="iconfirst" />
                          <span>
                            <strong>{totalItems}</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <img src="../assets/icon/bay.svg" alt="" />
                          <span>
                            <strong>BAY 1 | LANE 3</strong>
                          </span>
                        </li>
                        <li>
                          <img src="../assets/icon/time.svg" alt="" />
                          <span>
                            <strong>4 PM CST</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <Link to="/upcoming/class-check-in-form">
                            <ButtonAdd
                              Heading="New"
                              images="../assets/icon/add_user.svg"
                              active="orange-outline"
                              bgChange="white-bg"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {courseList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Classes" />
                    )}
                    {isLoading && <SkeletonLoader />}
                    {courseList?.length > 0 && !isLoading && (
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table className="table table-time-gun-create mobile-responsive mobile">
                              <thead>
                                <tr>
                                  <th>NAME</th>
                                  <th>START TIME </th>
                                  <th>END TIME </th>
                                  <th>Skill Level</th>
                                  <th>ACTIVE</th>
                                  <th>FEATURED</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody className="table-time-gun-create__old-even">
                                {courseList?.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      className="mob-tab-view td-left"
                                      key={index}
                                    >
                                      <HexagonSize
                                        imageUser={
                                          item?.banner
                                            ? item?.banner?.fullUrl
                                            : '../assets/images/class.png'
                                        }
                                      />
                                    </td>
                                    <td
                                      data-label="Name"
                                      style={{ width: '200px' }}
                                    >
                                      <span className="user-n-2 mob-tab-view-2">
                                        <HexagonSize
                                          imageUser={
                                            item?.banner
                                              ? item?.banner?.fullUrl
                                              : '../assets/images/class.png'
                                          }
                                        />
                                        {item?.name}
                                      </span>
                                      <span className="mob-tab-view">
                                        {item?.name}
                                      </span>
                                    </td>
                                    <td data-label="Start">
                                      <p>
                                        {moment
                                          .unix(item?.start_date_time_unix)
                                          .format('DD-MM-YYYY')}
                                      </p>
                                      <p>
                                        {moment
                                          .unix(item?.start_date_time_unix)
                                          .format('hh:mm A')}
                                      </p>
                                    </td>
                                    <td data-label="End">
                                      <p className="w-set-table-time">
                                        {moment
                                          .unix(item?.end_date_time_unix)
                                          .format('DD-MM-YYYY')}
                                      </p>
                                      <p>
                                        {moment
                                          .unix(item?.end_date_time_unix)
                                          .format('hh:mm A')}
                                      </p>
                                    </td>
                                    <td data-label="Location">
                                      <p className="w-set-table">
                                        {item?.skillLevelName}
                                      </p>
                                    </td>

                                    <td data-label="Active">
                                      <div className="form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={item?.is_active}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <td data-label="Featured">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item?.is_featured}
                                        id="active"
                                        disabled
                                      />
                                      <label
                                        className="form-check-label color-theme ps-1"
                                        htmlFor="active"
                                      >
                                        Active
                                      </label>
                                    </td>

                                    <td className="td-left">
                                      <div className="edit-user-id">
                                        <Link
                                          to="/upcoming/class-check-in-form"
                                          state={{
                                            course: item,
                                          }}
                                          className="or-col border-theme"
                                        >
                                          <img
                                            src="../assets/icon/edit.svg"
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          itemsPerPage={itemsPerPage}
                          totalItems={totalItems}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                          setItemsPerPage={setItemsPerPage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Class;
