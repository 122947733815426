import React, { useEffect, useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import { Link, useLocation } from 'react-router-dom';
import Label from '../Components/Label';
import RichTextEditor from '../Components/RichTextEditor';
import { getItem, getObject, saveObject } from '../Utils/LocalStorage';
import Multiselect from 'multiselect-react-dropdown';
import Logger from '../Utils/Logger';
// import HexagonSize from '../Components/HexagonSize';
import {
  CATEGORY_LIST_ENDPOINT,
  COURSE_ENDPOINT,
  LIST_BAY_ENDPOINT,
  LIST_BAY_LANES_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ButtonLoader from '../Components/ButtonLoader';
import ImageUploadInput from '../Components/ImageUploadInput';
import Toast from '../Utils/Toast';
import * as Yup from 'yup';
import { COURSE_CLASS } from '../Utils/ModelClasses';
import { COURSE_BANNER_TYPE } from '../Utils/UploadType';
import { uploadFile } from '../Utils/upload';
import { convertTimeToUnix } from '../Utils/common';
import moment from 'moment';
import UpdateProductsModal from '../Components/UpdateProductsModal';
import PropTypes from 'prop-types';

// Validation schema
const CourseRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  skillLevel: Yup.string().required('Required'),
  eventStartAt: Yup.string().required('Required'),
  doorOpenTime: Yup.string().required('Required'),
  eventEndAt: Yup.string().required('Required'),
  session: Yup.string().required('Required'),
  selectedLocation: Yup.string().required('Required'),
});

const ClassCheckInForm = ({ handleNextStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [about, setAbout] = useState('');
  const [prerequisite, setPrerequisite] = useState('');
  const [duration, setDuration] = useState(0);
  const [skillLevel, setSkillLevel] = useState('');
  const [session, setSession] = useState('');
  const [coin, setCoin] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [courseUrl, setCourseUrl] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [doorOpenTime, setDoorOpenTime] = useState('');
  const [eventStartAt, setEventStartAt] = useState('');
  const [eventEndAt, setEventEndAt] = useState('');
  const [hideEventAfterExpiration, setHideEventAfterExpiration] =
    useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [courseID, setCourseID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [file, setFile] = useState(null);
  const rangeId = getItem('rangeId');
  const location = useLocation();
  const [imageData, setImageData] = useState({});
  const [bayList, setBayList] = useState([]);
  const [laneList, setLaneList] = useState([]);
  const [laneId, setLaneId] = useState([]);
  const [bayId, setBayId] = useState([]);

  const [createdClassData] = useState(getObject('classData'));
  const selectedCourse =
    (location.state && location.state.course) || createdClassData;

  useEffect(() => {
    fetchCategoryList();
    fetchBayList();
  }, []);

  const fetchBayList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(LIST_BAY_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setBayList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategoryList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        CATEGORY_LIST_ENDPOINT + '?type=COURSE'
      );
      if (response.data.code === 200) {
        setCategory(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCourse?.id) {
      setCourseID(selectedCourse?.id);
      setName(selectedCourse?.name);
      setSelectedCategory(
        selectedCourse?.entity_has_categories.map((item) => item.category)
      );
      setIsFeatured(selectedCourse?.is_featured);
      setIsActive(selectedCourse?.is_active);
      setDescription(
        selectedCourse?.description !== null ? selectedCourse?.description : ''
      );
      setAbout(selectedCourse?.about !== null ? selectedCourse?.about : '');
      setPrerequisite(selectedCourse?.prerequisite);
      setDuration(
        selectedCourse?.duration ? parseInt(selectedCourse?.duration) : 0
      );
      setSession(selectedCourse?.session ? selectedCourse?.session : '');
      setCoin(selectedCourse?.coins ? selectedCourse?.coins : 0);
      setSkillLevel(selectedCourse?.skill_level);
      setCourseUrl(selectedCourse?.course_url);
      setImageData(selectedCourse?.banner);
      setTermsAndConditions(
        selectedCourse?.terms_and_conditions !== null
          ? selectedCourse?.terms_and_conditions
          : ''
      );
      setDoorOpenTime(
        moment
          .unix(selectedCourse?.door_open_date_time_unix)
          .format('YYYY-MM-DDTHH:mm')
      );
      setEventStartAt(
        moment
          .unix(selectedCourse?.start_date_time_unix)
          .format('YYYY-MM-DDTHH:mm')
      );
      setEventEndAt(
        moment
          .unix(selectedCourse?.end_date_time_unix)
          .format('YYYY-MM-DDTHH:mm')
      );
      setHideEventAfterExpiration(selectedCourse?.hide_after_expiration);
      saveObject('classData', selectedCourse);
    }
  }, [selectedCourse]);

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  const validate = () => {
    try {
      CourseRangeSchema.validateSync(
        {
          name,
          skillLevel,
          eventStartAt,
          eventEndAt,
          doorOpenTime,
          session,
          selectedLocation,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseCourseID;
      let res, resData;
      if (!courseID) {
        const filteredArray = selectedCategory.map((item) => item.id);
        const response = await axiosInstance.post(COURSE_ENDPOINT + rangeId, {
          name: name,
          description: description, //nullable | string
          about: about, //nullable | string
          prerequisite: prerequisite, //nullable | string
          duration: parseInt(duration), //nullable | integer
          skill_level: skillLevel, //required | string | ENTRY / INTERMEDIATE / EXPERT
          session: session, //nullable | integer
          coins: coin, //nullable | integer
          course_url: courseUrl, //nullable | string | URL
          terms_and_conditions: termsAndConditions, //nullable|string
          door_open_date_time_unix: doorOpenTime
            ? convertTimeToUnix(doorOpenTime)
            : doorOpenTime, // nullable|integer|unix UTC timestamp
          start_date_time_unix: convertTimeToUnix(eventStartAt), // required|integer|unix UTC timestamp
          end_date_time_unix: convertTimeToUnix(eventEndAt), // required|integer|unix UTC timestamp
          hide_after_expiration: hideEventAfterExpiration ? 1 : 0, //boolean|0/1
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseCourseID = response.data.data.id;
        res = response.data.message;
        resData = response.data.data;
        setCourseID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            COURSE_CLASS,
            responseCourseID ? responseCourseID : courseID,
            COURSE_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
      saveObject('classData', resData);
      Toast.success(res ? res : 'Class successfully created.', {
        onClose: () => {
          handleNextStep();
        },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseCourseID;
      let res, resData;

      const filteredArray = selectedCategory.map((item) => item.id);
      const response = await axiosInstance.patch(
        COURSE_ENDPOINT + selectedCourse?.id + `/${rangeId}`,
        {
          name: name,
          description: description,
          about: about,
          prerequisite: prerequisite,
          duration: parseInt(duration),
          skill_level: skillLevel,
          session: session,
          coins: coin,
          course_url: courseUrl,
          terms_and_conditions: termsAndConditions,
          door_open_date_time_unix: doorOpenTime
            ? convertTimeToUnix(doorOpenTime)
            : doorOpenTime,
          start_date_time_unix: convertTimeToUnix(eventStartAt),
          end_date_time_unix: convertTimeToUnix(eventEndAt),
          hide_after_expiration: hideEventAfterExpiration ? 1 : 0,
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
        }
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      responseCourseID = response.data.data.id;
      res = response.data.message;
      resData = response.data.data;

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            COURSE_CLASS,
            responseCourseID ? responseCourseID : courseID,
            COURSE_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
      saveObject('classData', resData);

      Toast.success(res, {
        onClose: () => {
          handleNextStep();
        },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleReset = () => {
  //   setFile(null);
  //   setImageID(null);
  //   setErrors({});
  //   setGeneralError('');
  //   setCourseID(null);
  //   setName('');
  //   setDescription('');
  //   setAbout('');
  //   setPrerequisite('');
  //   setDuration(0);
  //   setSkillLevel('');
  //   setSession(0);
  //   setCoin(0);
  //   setCourseUrl('');
  //   setTermsAndConditions('');
  //   setDoorOpenTime('');
  //   setEventStartAt('');
  //   setEventEndAt('');
  //   setHideEventAfterExpiration(false);
  //   setIsActive(false);
  //   setIsFeatured(false);
  //   setSelectedCategory([]);
  // };

  const handleEventStartAtChange = (e) => {
    const eventStartAt = e.target.value;
    setEventStartAt(eventStartAt);
    // Update doorOpenTime to be the same as eventStartAt
    setDoorOpenTime(eventStartAt);
    // Check if session duration is not empty or zero
    if (session > 0) {
      // Calculate eventEndAt based on session duration
      const eventEndAt = moment(eventStartAt)
        .add(session, 'minutes')
        .format('YYYY-MM-DDTHH:mm');
      setEventEndAt(eventEndAt);
    }
  };

  const handleChangeBay = async (id) => {
    try {
      setLaneId('');
      setLaneList([]);
      if (id === '') {
        setBayId('');
        return;
      }
      setIsLoading(true);
      setBayId(id);
      const response = await axiosInstance.get(LIST_BAY_LANES_ENDPOINT + id);
      if (response.data.code === 200) {
        setLaneList(response?.data?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="employees employ-set pt-4 pt-md-1">
        <div className="back-listing-tab">
          <Link to="/upcoming/class" className="button-width">
            <ButtonAdd
              Heading=""
              images="../assets/icon/back-icon-page.svg"
              active="orange-outline"
              bgChange="white-bg "
            />
          </Link>
          <h3 className="card-top-2 h-set-2">
            {selectedCourse?.id ? 'Update' : 'New '} Class
          </h3>
        </div>
        <div className="all-date-selected mt-3  new-back-btn-add">
          <Link to="/upcoming/class" className="button-width b-p">
            <ButtonAdd
              Heading=""
              images="../assets/icon/back-icon-page.svg"
              active="orange-outline"
              bgChange="white-bg"
            />
          </Link>
        </div>
      </div>
      <form
        className="triangle-top"
        onSubmit={selectedCourse?.id ? handleUpdate : handleSubmit}
      >
        <div className="body-text text-start">
          <div className="row">
            <div className="col-lg-4">
              <Label labelHeading="Name" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme input-mb"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder=""
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
            </div>
            <div className="col-lg-4">
              <Label labelHeading="Prerequisite" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  onChange={(e) => setPrerequisite(e.target.value)}
                  value={prerequisite}
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-lg-4">
              <Label labelHeading="Duration" />
              <div className="tickets">
                <input
                  type="number"
                  className="form-control input-theme"
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <Label labelHeading="Short Description" />
              <div className="tickets-2">
                <RichTextEditor
                  value={about}
                  setEditorData={setAbout}
                  index="about"
                />
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <Label labelHeading="Long Description" />
              <div className="tickets-2">
                <RichTextEditor
                  value={description}
                  setEditorData={setDescription}
                  index="description"
                />
              </div>
            </div>
            <div className="col-md-4">
              <Label labelHeading="Skill Level" />
              <div className="tickets">
                <select
                  id="inputState"
                  className="form-select input-mb"
                  onChange={(e) => setSkillLevel(e.target.value)}
                  value={skillLevel}
                >
                  <option value="">Select.....</option>
                  <option value="ENTRY">ENTRY</option>
                  <option value="INTERMEDIATE">INTERMEDIATE</option>
                  <option value="EXPERT">EXPERT</option>
                </select>
                {errors.skillLevel && (
                  <div className="error">{errors.skillLevel}</div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <Label labelHeading="Session Duration" />
              <div className="tickets">
                <select
                  name="time"
                  id="time"
                  className="form-select mb-0"
                  value={session}
                  onChange={(e) => setSession(e.target.value)}
                >
                  <option value="">Select Duration</option>
                  <option value={30}>30 Minutes</option>
                  <option value={45}>45 Minutes</option>
                  <option value={60}>60 Minutes</option>
                  <option value={120}>120 Minutes</option>
                </select>
                {errors.session && (
                  <div className="error">{errors.session}</div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <Label labelHeading="Coins" />
              <div className="tickets">
                <input
                  type="number"
                  className="form-control input-theme"
                  onChange={(e) => setCoin(e.target.value)}
                  value={coin}
                />
              </div>
            </div>
            <div className="col-lg-4 ">
              <Label labelHeading="Event Start" />
              <div className="tickets">
                <input
                  type="datetime-local"
                  className="form-control input-theme input-mb"
                  onChange={handleEventStartAtChange}
                  value={eventStartAt}
                />
                {errors.eventStartAt && (
                  <div className="error">{errors.eventStartAt}</div>
                )}
              </div>
            </div>
            <div className="col-lg-4 ">
              <Label labelHeading="Event End" />
              <div className="tickets">
                <input
                  type="datetime-local"
                  className="form-control input-theme input-mb"
                  onChange={(e) => {
                    setEventEndAt(e.target.value);
                  }}
                  value={eventEndAt}
                />
                {errors.eventEndAt && (
                  <div className="error">{errors.eventEndAt}</div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <Label labelHeading="Door Open Time" addCss="mt-3 mt-lg-0" />
              <div className="tickets">
                <input
                  type="datetime-local"
                  onChange={(e) => setDoorOpenTime(e.target.value)}
                  value={doorOpenTime}
                  className="form-control input-theme input-mb"
                />
                {errors.doorOpenTime && (
                  <div className="error">{errors.doorOpenTime}</div>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <Label labelHeading="Location" />
              <div className="tickets">
                <select
                  name="location"
                  id="location"
                  className="form-select mb-0"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value="">Select Location</option>
                  <option value="Premise">Premise</option>
                  <option value="Virtual">Virtual</option>
                </select>
                {errors.selectedLocation && (
                  <div className="error">{errors.selectedLocation}</div>
                )}
              </div>
            </div>

            {selectedLocation === 'Premise' && (
              <>
                <div className="col-lg-3">
                  <Label labelHeading="Bay" />
                  <div className="tickets">
                    <select
                      name="bay"
                      id="bay"
                      className="form-select mb-0"
                      required
                      value={bayId}
                      disabled={isLoading || bayList?.length === 0}
                      onChange={(e) => handleChangeBay(e.target.value)}
                    >
                      <option value="">Bay&lsquo;s</option>
                      {bayList?.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Label labelHeading="Lane" />
                  <div className="tickets">
                    <select
                      name="lane"
                      id="lane"
                      required
                      className="form-select mb-0"
                      value={laneId}
                      disabled={isLoading || laneList?.length === 0}
                      onChange={(e) => setLaneId(e.target.value)}
                    >
                      <option value="">Lane</option>
                      {laneList?.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
            {selectedLocation === 'Virtual' && (
              <div className="col-lg-4">
                <Label labelHeading="Course URL" />
                <div className="tickets">
                  <input
                    type="text"
                    className="form-control input-theme"
                    onChange={(e) => setCourseUrl(e.target.value)}
                    value={courseUrl}
                    required
                  />
                </div>
              </div>
            )}

            <div className="col-md-3">
              <Label labelHeading="Categories" />
              <div className="tickets">
                <Multiselect
                  isObject={true}
                  options={category}
                  selectedValues={selectedCategory}
                  showCheckbox
                  onSelect={(option) => setSelectedCategory(option)}
                  onRemove={(option) => setSelectedCategory(option)}
                  displayValue="name"
                />
              </div>
            </div>

            <div className="col-md-12 mb-2 mt-2">
              <Label labelHeading="Terms and Conditions" />
              <div className="tickets-2">
                <RichTextEditor
                  value={termsAndConditions}
                  setEditorData={setTermsAndConditions}
                  index="termsAndConditions"
                />
              </div>
            </div>
            <div className="col-md-4 my-3">
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className="form-check-input"
                checked={hideEventAfterExpiration}
                onChange={(e) => setHideEventAfterExpiration(e.target.checked)}
              />
              <label htmlFor="vehicle1" className="form-check-label ps-1">
                Hide Event After Expiration
              </label>
            </div>
            <div className="col-md-4 my-3">
              <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                value="Bike"
                className="form-check-input"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
              <label htmlFor="vehicle2" className="form-check-label ps-1">
                Active
              </label>
            </div>
            <div className="col-md-4 my-3">
              <input
                type="checkbox"
                id="vehicle3"
                name="vehicle3"
                value="Bike"
                className="form-check-input"
                checked={isFeatured}
                onChange={(e) => setIsFeatured(e.target.checked)}
              />
              <label htmlFor="vehicle3" className="form-check-label ps-1">
                Featured
              </label>
            </div>
            <div className="col-md-12 mb-4 mt-2">
              <div className="button-wrapper d-none">
                <span className="label-input">
                  <strong>Product Image</strong>
                  Selected files are not actually uploaded. Recommended Size
                  1920 X 700
                </span>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  className="upload-box"
                  placeholder="Upload File2"
                />
              </div>
              <ImageUploadInput
                setFile={setFile}
                handleDeleteImage={handleDeleteImage}
                imageData={imageData}
              />
            </div>
            {generalError && (
              <p className="error text-center mt-3">{generalError}</p>
            )}
            <div className="set-button">
              <ButtonLoader
                type="submit"
                bgChange={`white-bg w-pd-add save-w`}
                active="orange-outline"
                isLoading={isLoading}
              >
                {selectedCourse?.id ? 'Update' : 'Save'}
              </ButtonLoader>
              {courseID && (
                <Link to="#" onClick={handleNextStep}>
                  <ButtonRemoveImage
                    Heading="Next"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </form>
      <UpdateProductsModal />
    </>
  );
};

ClassCheckInForm.propTypes = {
  handleNextStep: PropTypes.func,
};
export default ClassCheckInForm;
